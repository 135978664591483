// history.js
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();

const notifyCustomerio = (path) => {
  if (window._cio && typeof window._cio.page === 'function') window._cio.page(path);
};

const originalPush = history.push;
const originalReplace = history.replace;

history.push = (path, state) => {
  originalPush(path, state);
  notifyCustomerio(typeof path === 'string' ? path : path.pathname);
};

history.replace = (path, state) => {
  originalReplace(path, state);
  notifyCustomerio(typeof path === 'string' ? path : path.pathname);
};

history.listen((location, action) => {
  if (action === 'POP') {
    return history.replace({pathname: location.pathname, search: location.search || ''}, {source: ''});
  }
});

export default history;
