import React from 'react';
import EyeInvisible from '../../new-ui/components/svg/eyeInvisible';
import EyeVisible from '../../new-ui/components/svg/eyeVisible';

const MIN_LENGTH_PASSWORD = 6;
const MAX_LENGTH_PASSWORD = 32;

class PasswordWithConfirmation extends React.Component {
  state = {
    pwdContainUpperCase: false,
    pwdContainSymbol: false,
    pwdMissMatch: false,
    confirmPwdEmpty: true,
    regUpperCase: /[A-Z]/,
    regSymbol: /[^\w\s]/, // Includes all symbols except _
    showPwdRequirements: false,
    confirmPwdMessage: false,
    pwdContainMinLength: false,
    pwdContainMaxLength: true,
    pwdConfirmationDisabled: true,
    inputType: 'password',
    inputTypeConfirmation: 'password'
  };

  toggleInputType = (number) => {
    if (number === 1) {
      return this.setState({inputType: this.state.inputType === 'text' ? 'password' : 'text'});
    } else {
      return this.setState({inputTypeConfirmation: this.state.inputTypeConfirmation === 'text' ? 'password' : 'text'});
    }
  };

  validStrongPassword = () => {
    return this.props.validStrongPassword(
      this.state.pwdContainUpperCase &&
        this.state.pwdContainSymbol &&
        this.state.pwdContainMinLength &&
        !this.state.confirmPwdEmpty &&
        !this.state.pwdMissMatch
    );
  };

  checkStrongPassword = (e) => {
    this.setState(
      {
        pwdContainUpperCase: this.state.regUpperCase.test(e.target.value),
        pwdContainSymbol: this.state.regSymbol.test(e.target.value) || e.target.value.includes('_'),
        pwdContainMinLength: e.target.value.length >= MIN_LENGTH_PASSWORD,
        pwdContainMaxLength: e.target.value.length <= MAX_LENGTH_PASSWORD
      },
      () => {
        if (
          this.state.pwdContainUpperCase &&
          this.state.pwdContainSymbol &&
          this.state.pwdContainMinLength &&
          this.state.pwdContainMaxLength
        ) {
          this.setState({
            pwdConfirmationDisabled: false
          });
        } else {
          this.setState({
            pwdConfirmationDisabled: true
          });
          this.hideConfirmPassword();
        }
        this.matchConfirmPassword();
      }
    );
  };

  matchConfirmPassword = (e) => {
    let userPassword = document.getElementById('user_password');
    let confirmPassword = document.getElementById('user_password_confirmation').value;

    this.setState(
      {
        pwdMissMatch: userPassword.value !== confirmPassword,
        confirmPwdEmpty: confirmPassword.length === 0,
        confirmPwdMessage: true
      },
      () => {
        this.validStrongPassword();
      }
    );
  };

  showPwdRequirementsView = () => {
    return (
      this.state.showPwdRequirements && (
        <div className="strong-password-requirements">
          <p>{I18n.t('signup.strong_password')}</p>
          <p className={this.state.pwdContainMinLength ? 'req-accepted' : 'req-rejected'}>
            {this.state.pwdContainMinLength ? '✓' : '✗'} {I18n.t('signup.min_length')}
          </p>
          <p className={this.state.pwdContainMaxLength ? 'req-accepted' : 'req-rejected'}>
            {this.state.pwdContainMaxLength ? '✓' : '✗'} {I18n.t('signup.max_length')}
          </p>
          <p className={this.state.pwdContainUpperCase ? 'req-accepted' : 'req-rejected'}>
            {this.state.pwdContainUpperCase ? '✓' : '✗'} {I18n.t('signup.uppercase')}
          </p>
          <p className={this.state.pwdContainSymbol ? 'req-accepted' : 'req-rejected'}>
            {this.state.pwdContainSymbol ? '✓' : '✗'} {I18n.t('signup.symbol')}
          </p>
        </div>
      )
    );
  };

  showPwdRequirements = () => {
    this.setState({
      showPwdRequirements: true
    });
  };

  showConfirmPasswordView = () => {
    return (
      this.state.confirmPwdMessage &&
      (this.state.pwdMissMatch && !this.state.confirmPwdEmpty ? (
        <div className="strong-password-requirements">
          <p className="req-rejected">{I18n.t('signup.password_missmatch')}</p>
        </div>
      ) : (
        !this.state.confirmPwdEmpty && (
          <div className="strong-password-requirements">
            <p className="req-accepted">{I18n.t('signup.password_match')}</p>
          </div>
        )
      ))
    );
  };

  hideConfirmPassword = () => {
    this.setState({
      confirmPwdMessage: false
    });
  };

  checkForm = (source) => {
    switch (source) {
      case 'profile':
        return (
          <div>
            <li className="form-group">
              <label htmlFor="user_password" className="input-newui__label">
                {' '}
                {I18n.t('v1.login.password')}
              </label>
              <input
                className="input-newui__input"
                id="user_password"
                name="user[password]"
                tabIndex="5"
                type="password"
                onFocus={this.showPwdRequirements}
                onBlur={this.hidePwdRequirements}
                onChange={this.checkStrongPassword}
              />
            </li>
            {this.showPwdRequirementsView()}
            <li className="form-group">
              <label htmlFor="user_password_confirmation" className="input-newui__label">
                {I18n.t('v1.signup.password_confirm')}
              </label>
              <input
                className="input-newui__input"
                id="user_password_confirmation"
                name="user[password_confirmation]"
                tabIndex="6"
                type="password"
                onChange={this.matchConfirmPassword}
                onFocus={this.matchConfirmPassword}
                disabled={this.state.pwdConfirmationDisabled ? 'disabled' : ''}
              />
            </li>
            {this.showConfirmPasswordView()}
          </div>
        );
      case 'new_user':
        return (
          <div>
            <div className="form-group modal-edit__input-container password mxwidht-2">
              <label htmlFor="user_password" className="modal-edit__label">
                {I18n.t('user.create_user.password')}
              </label>
              <input
                className="modal-edit__input"
                id="user_password"
                name="user[password]"
                tabIndex="5"
                type={this.state.inputType}
                onFocus={this.showPwdRequirements}
                onBlur={this.hidePwdRequirements}
                onChange={this.checkStrongPassword}
              />
              <a rel="noopener" href="#" className="visibility" onClick={() => this.toggleInputType(1)}>
                {this.state.inputType === 'text' ? <EyeVisible color="#787D86" /> : <EyeInvisible color="#787D86" />}
              </a>
              {this.showPwdRequirementsView()}
            </div>
            <div className="form-group modal-edit__input-container password mxwidht-2">
              <label htmlFor="user_password_confirmation" className="modal-edit__label">
                {I18n.t('user.create_user.password_confirmation')}
              </label>
              <input
                className="modal-edit__input"
                id="user_password_confirmation"
                name="user[password_confirmation]"
                tabIndex="6"
                type={this.state.inputTypeConfirmation}
                onChange={this.matchConfirmPassword}
                onFocus={this.matchConfirmPassword}
                disabled={this.state.pwdConfirmationDisabled ? 'disabled' : ''}
              />
              <a rel="noopener" href="#" className="visibility" onClick={() => this.toggleInputType(2)}>
                {this.state.inputTypeConfirmation === 'text' ? (
                  <EyeVisible color="#787D86" />
                ) : (
                  <EyeInvisible color="#787D86" />
                )}
              </a>
              {this.showConfirmPasswordView()}
            </div>
          </div>
        );
      case 'signup':
        return (
          <div style={{display: 'flex'}} className="login-newui__inputs-passwords">
            <li className="sep">
              <label className="login-newui__label" htmlFor="user_password">
                {I18n.t('signup.password')}
              </label>
              <input
                tabIndex="4"
                className="login-newui__input"
                type="password"
                name="user[password]"
                id="user_password"
                onFocus={this.showPwdRequirements}
                onBlur={this.hidePwdRequirements}
                onChange={this.checkStrongPassword}
              />
              {this.props.errorsFor('password')}
              {this.showPwdRequirementsView()}
            </li>

            <li className="sep">
              <label className="login-newui__label" htmlFor="user_password_confirmation">
                {I18n.t('signup.password_confirm')}
              </label>
              <input
                tabIndex="5"
                className="login-newui__input"
                type="password"
                name="user[password_confirmation]"
                id="user_password_confirmation"
                onChange={this.matchConfirmPassword}
                onFocus={this.matchConfirmPassword}
                disabled={this.state.pwdConfirmationDisabled ? 'login-newui__input--disabled' : ''}
              />
              {this.props.errorsFor('password_confirmation')}
              {this.showConfirmPasswordView()}
            </li>
          </div>
        );
    }
  };

  render() {
    return <div style={{width: '100%'}}>{this.checkForm(this.props.source)}</div>;
  }
}

export default PasswordWithConfirmation;
