/**
 * Performs a fetch request to the specified URL with the given method and body.
 * Automatically injects a CSRF token from the meta tag, and sets the Content-Type header to application/json.
 * If the response is not ok (status not in the range 200-299), rejects with an object containing the status, a message, and any available errors.
 * If successful, resolves with an object containing the status, the parsed response JSON, and a message.
 * If a network error occurs, rejects with an object containing a status of 500, a message, and any available errors.
 * @param {string} method - The HTTP method to use (e.g. GET, POST, PUT, DELETE)
 * @param {string} url - The URL to make the request to
 * @param {object} [body] - An object containing data to be sent with the request
 * @param {boolean} [isUrlEncoded] - Whether the request body should be URL encoded or not
 * @returns {Promise<Object>}
 */
export async function client(method, url, body = null, isUrlEncoded = false) {
  // Set the CSRF token in the headers
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  };

  const options = {
    method,
    headers
  };

  if (body !== null && (method === 'POST' || method === 'PUT' || method === 'DELETE')) {
    options.headers['Content-Type'] = isUrlEncoded ? 'application/x-www-form-urlencoded' : 'application/json';
    options.body = isUrlEncoded ? body : JSON.stringify(body);
  }

  const response = await fetch(url, options);

  // Check if the re  sponse is ok (status in the range 200-299)
  if (!response.ok) {
    const errorData = await response.json();
    return Promise.reject({
      status: response.status,
      error: errorData
    });
  }

  // If successful, parse and return the response JSON
  const data = await response.json();
  return {
    status: response.status,
    data,
    message: 'Request successful'
  };
}

export async function clientWithBinary(method, url, body) {
  // Set the CSRF token in the headers
  const headers = {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  };

  const options = {
    method,
    headers
  };

  if (body !== null && (method === 'POST' || method === 'PUT' || method === 'DELETE')) {
    options.body = body;
  }

  const response = await fetch(url, options);

  // Check if the re  sponse is ok (status in the range 200-299)
  if (!response.ok) {
    const errorData = await response.json();
    return Promise.reject({
      status: response.status,
      error: errorData
    });
  }

  // If successful, parse and return the response JSON
  const data = await response.json();
  return {
    status: response.status,
    data,
    message: 'Request successful'
  };
}
