import React from 'react';
import actioncable from 'actioncable';
import Typist from 'react-typist';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';
import NewIcoUnexpectedError from '../thewatch/new-ui/components/svg/newIcoUnexpectedError.js';
import SuccessVerifyEmail from '../thewatch/new-ui/components/svg/successVerifyEmail.js';
import {client} from '../thewatch/fetch/client.js';

window.App = {
  cable: actioncable.createConsumer()
};

class Waiting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      server_error: null,
      event_info: {},
      status: 'connecting',
      network_status: navigator.onLine,
      user_email: '...'
    };
  }

  componentDidMount() {
    client('GET', `${location.pathname}.json`).then((response) => {
      const data = response.data;
      if (data.status === 'expired') {
        location.href = '/';
      } else {
        this.setState(
          {
            token: data.token,
            status: data.status,
            os_node: data.os_node,
            verify_email: data.verify_email,
            user_email: data.user_email
          },
          this.subscribe
        );
      }
    });

    setTimeout(() => {
      this.setState({status: 'server_error'});
    }, 60000);
  }

  checkNetworkStatus = () => {
    return setInterval(() => {
      return this.setState({
        network_status: navigator.onLine
      });
    }, 2000);
  };

  connected = () => {
    return this.checkNetworkStatus();
  };

  subscribe = () => {
    return (App.events = App.cable.subscriptions.create(
      {
        channel: 'ClientConfigurationChannel',
        room: this.state.token
      },
      {
        connected: function () {},
        disconnected: function () {},
        received: function (data) {
          return this.handleMessage(data.message);
        },
        notify: () => {
          return this.perform('notify');
        },
        handleMessage: (event) => {
          console.log('status: ' + event.status);
          if (event.status === 'ok') {
            if (this.state.verify_email) {
              return this.setState({
                event_info: event,
                status: 'success_verify_email'
              });
            } else {
              this.setState({
                event_info: event,
                status: 'success'
              });
            }
          } else {
            return this.setState({
              status: 'client_error'
            });
          }
        }
      }
    ));
  };

  render() {
    return (
      <div className="session-container">
        <div className="top-icon">
          <figure>
            <NewIcoPrey />
          </figure>
        </div>

        <div>
          <Statuses
            status={this.state.status}
            os_node={this.state.os_node}
            event_info={this.state.event_info}
            user_email={this.state.user_email}
          />
        </div>
      </div>
    );
  }
}

class Statuses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }
  // needed for typist to work, just mount and unmount
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props.status) {
      return this.setState(
        {
          show: false
        },
        () => {
          return setTimeout(() => {
            this.setState({
              show: true
            });
            return 200;
          });
        }
      );
    }
  }

  render() {
    return this.state.show && <StatusItem {...this.props} />;
  }
}

class StatusItem extends React.Component {
  state = {
    isButtonVisible: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({isButtonVisible: true});
    }, 10000);
  }

  redirectHome = (ev) => {
    ev.preventDefault();
    return (location.href = '/');
  };

  goToDevice = (e) => {
    e.preventDefault();
    location.href = `/devices/${this.props.event_info.device_key}`;
  };

  goToMyAccount = () => {
    location.href = `/`;
  };

  gotToHelpCenter = () => {
    window.open(I18n.t('node_config.waiting.error.help_center_link'), '_blank', 'noopener,noreferrer');
  };

  formatOS = (os) => {
    switch (os) {
      case 'mac':
        return 'macOS';
      default:
        return os;
    }
  };

  render() {
    return (
      <div>
        {function () {
          switch (this.props.status) {
            case 'connecting':
              return (
                <div className="flex-column gap-64">
                  <div className="loading-text">
                    <h1>{I18n.t('node_config.waiting.connected.title', {email: this.props.user_email})}</h1>
                  </div>
                  <div className="loader-big">
                    <figure className="loader xxlarge" />
                  </div>
                  <div className="placeholder_space">
                    {this.state.isButtonVisible && (
                      <div className="loading-text">
                        <p>
                          <strong>{I18n.t('node_config.waiting.connected.long_wait')}</strong>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            case 'disconnected':
              return (
                <div className="device-disconnected">
                  <Typist avgTypingDelay={40}>
                    <h1>{I18n.t('node_config.waiting.disconnected.title')}</h1>
                    <p>{I18n.t('node_config.waiting.disconnected.desc')}</p>
                    <a rel="noopener" href="/" className="btn default">
                      {I18n.t('misc.back_to_site')}
                    </a>
                  </Typist>
                </div>
              );
            case 'server_error':
            case 'client_error':
              return (
                <div className="flex-column gap-24">
                  <h1 className="text-center">{I18n.t('node_config.waiting.error.title')}</h1>
                  <div className="align-self-center">
                    <NewIcoUnexpectedError />
                  </div>
                  <div className="login-newui__link-container flex-column gap-24">
                    <button className="button button--primary expanded" onClick={this.goToMyAccount}>
                      {I18n.t('node_config.waiting.error.cta')}
                    </button>

                    <button className="button button--secondary expanded" onClick={this.gotToHelpCenter}>
                      {I18n.t('node_config.waiting.error.help')}
                    </button>
                  </div>
                </div>
              );
            case 'success_verify_email':
              return (
                <div className="success-verify-email__card">
                  <h2>{I18n.t('messages.signup.email_title')}</h2>
                  <p>{I18n.t('messages.signup.success_free')}</p>
                  <p>{I18n.t('messages.signup.can_close_window')}</p>
                  <figure className="illustration-success">
                    <SuccessVerifyEmail />
                  </figure>
                </div>
              );
            case 'success':
              return (
                <div className="fui-success flex-column gap-16">
                  <h2>{I18n.t('node_config.waiting.success.title')} </h2>
                  <div className="device-info">
                    <div className="flex-column gap-16">
                      <ul className="flex-column gap-16">
                        <li className="flex-column gap-8">
                          <div>{I18n.t('node_config.configured.device_name')}</div>
                          <div className="reveal-text">{this.props.event_info.hardware.name}</div>
                        </li>
                        <li className="flex-column gap-8">
                          <div>{I18n.t('node_config.configured.prey_version')}</div>
                          <div className="reveal-text">{this.props.event_info.version}</div>
                        </li>
                        <li className="flex-column gap-8">
                          <div>{I18n.t('node_config.configured.operating_system')}</div>
                          <div className="reveal-text">{this.formatOS(this.props.event_info.hardware.os)}</div>
                        </li>
                        <li className="flex-column gap-8">
                          <div>{I18n.t('node_config.configured.account_email')}</div>
                          <div className="reveal-text">{this.props.user_email}</div>
                        </li>
                      </ul>
                      <div className="align-self-end">
                        <button className="button button--primary" onClick={this.goToDevice}>
                          {I18n.t('node_config.configured.cta')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
          }
        }.call(this)}
      </div>
    );
  }
}

export default Waiting;
