import React from 'react';

const NewIcoAbout = ({ color }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17ZM9.48887 6.99963L8.4243 11.9988C8.34959 12.3599 8.45542 12.5653 8.74803 12.5653C8.95347 12.5653 9.26475 12.4906 9.47642 12.3038L9.38304 12.7458C9.07798 13.1132 8.40562 13.3809 7.82664 13.3809C7.07957 13.3809 6.76207 12.9326 6.96751 11.9801L7.75193 8.29456C7.82042 7.98328 7.75816 7.87122 7.44688 7.79651L6.96751 7.70935L7.05467 7.30469L9.48887 6.99963ZM8.5 5.84375C7.9132 5.84375 7.4375 5.36805 7.4375 4.78125C7.4375 4.19445 7.9132 3.71875 8.5 3.71875C9.0868 3.71875 9.5625 4.19445 9.5625 4.78125C9.5625 5.36805 9.0868 5.84375 8.5 5.84375Z" fill={color ? color : "#122F48"}/>
        </svg>
    );
}

export default NewIcoAbout;