import React from 'react';
import Footer from '../credentials/footer.js';

class Wizard extends React.Component {
  /**
   * @function constructor
   * @description Initializes the component with the given props and an initial state with step set to 1.
   * @param {object} props - The props given to the component.
   */
  constructor(props) {
    super(props);
    this.state = {step: 1};
  }

  /**
   * @function componentDidMount
   * @description Listens to url changes and updates the step state. If the user comes from the same url or from a callback, it automatically goes to step 2.
   */
  componentDidMount() {
    this.props.listenPath(this.updateWizard);
    if (document.location.href === document.referrer || document.location.href.match('callback')) {
      return this.updateWizard(2);
    }
  }

  /**
   * @function updateWizard
   * @description Updates the step state.
   * @param {number} step - The new step number.
   */
  updateWizard = (step) => {
    this.setState({
      step: step
    });
  };
  /**
   * @function renderStep
   * @description Renders the step markers with title and description.
   * @param {number} selectedStep - The selected step number.
   * @returns {ReactElement} A ReactElement containing the step markers.
   */
  renderStep(selectedStep = 1) {
    return (
      <div>
        <div className={`step-marker ${selectedStep === 1 ? 'selected' : ''}`}>
          <div className="desc">
            <h1>
              <span className="num">1</span>
              {I18n.t('node_config.steps.one.title')}
            </h1>
            {selectedStep === 1 && (
              <div>
                <h2>{I18n.t('node_config.steps.one.desc')}</h2>
                <p>{I18n.t('node_config.steps.one.desc_2')} </p>
              </div>
            )}
          </div>
        </div>
        <div className={`step-marker ${selectedStep === 2 ? 'selected' : ''}  `}>
          <div className="desc">
            <h1>
              <span className="num">2</span>
              {I18n.t('node_config.steps.two.title')}
            </h1>
            {selectedStep === 2 &&
              ((<h2>{I18n.t('node_config.steps.two.desc')}</h2>), (<p>{I18n.t('node_config.steps.two.desc_2')}</p>))}
          </div>
        </div>
      </div>
    );
  }
  /**
   * @function render
   * @description The render function for the component that renders the main wizard component.
   * @returns {JSX.Element} The rendered component.
   */
  render() {
    return (
      <div>
        <div className="hidden" id="network-alert">
          <div className="message">
            <p className="title" />
            <p className="content" />
          </div>
        </div>

        <div className="node" id="sso-login">
          <div className="button-group">
            <div className="layout">
              <div id="appRender" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Wizard;
