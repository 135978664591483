import React from 'react';
import CookieNotice from '../credentials/cookie_notice.js';

export default class Header extends React.Component {
  /**
   * @returns {React.ReactElement} A <CookieNotice /> element which handles the
   *          cookie consent policy.
   */
  render() {
    return <CookieNotice />;
  }
}
