const recaptcha = {
  userLang: function () {
    let keyValue = document.cookie.match('(^|;) ?' + 'lang' + '=([^;]*)(;|$)');
    keyValue ? unescape(keyValue[2]) : null;
  },

  requestFromIosClient: function () {
    return navigator.userAgent === 'Mozilla/5.0';
  },

  mount: function (callback, delegateReset = false) {
    const onSuccess = (token) => {
      return new Promise(function (resolve, reject) {
        if (grecaptcha === undefined) {
          alert('Recaptcha not defined');
          reject();
        }

        let response = grecaptcha.getResponse();

        if (!response) {
          alert('Coud not get recaptcha response');
          reject();
        }

        if (callback) {
          if (callback.length === 1) {
            callback(response);
          } else {
            callback[localStorage.getItem('recaptchaCallbackOption')](response);
          }
        }

        if (!delegateReset) {
          grecaptcha.reset();
        }
      });
    };

    let recaptcha;
    window.captchaCallBack = () => {
      recaptcha = grecaptcha.render('recaptcha', {
        sitekey: window.google_captcha_key,
        hl: this.userLang(),
        theme: 'dark',
        size: 'invisible',
        callback: onSuccess
      });
    };

    let script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaCallBack&render=explicit';
    script.async = true;
    document.body.appendChild(script);
    recaptcha = null;
  }
};

export {recaptcha};
