import React from 'react';
import serialize from 'form-serialize';
import {client} from '../thewatch/fetch/client.js';

class Reset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flashes: [],
      two_step: false,
      redirect: false,
      path: '',
      email: '',
      error: null,
      errors: {},
      loading: false
    };
  }

  componentDidMount() {
    client('GET', `${location.pathname}.json`)
      .then((response) => {
        const data = response.data;
        this.setState({
          two_step: data.two_step
        });
      })
      .catch((response) => {
        const error = response.error;
        console.error(error);
      });
  }

  errorsFor = (namespace) => {
    let errors;
    if ((errors = this.state.errors[namespace])) {
      return <p className="inline-errors critical">{errors.join(', ')}</p>;
    }
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({
      loading: true
    });

    const data = serialize(document.getElementById('form'), {empty: true, hash: false});
    client('POST', `${location.pathname}.json`, data, true)
      .then((response) => {
        const data = response.data;
        let obj = {
          path: data.path
        };
        if (data.flash) {
          obj['flashes'] = data.flash;
        }
        if (data.two_step) {
          obj['two_step'] = data.two_step;
        }
        if (data.redirect) {
          obj['redirect'] = data.redirect;
        }
        obj['loading'] = false;
        this.setState(obj, () => {
          if (data.path !== '/reset') {
            this.props.handleSuccess(data);
          }
        });
      })
      .catch((response) => {
        const error = response.error;
        this.setState({
          errors: error.errors,
          state: error.errors,
          flashes: error.flash,
          loading: false
        });
      });
  };

  render() {
    const buttonText = this.props.activation ? I18n.t('reset.activation_button') : I18n.t('reset.submit');
    const titleText = this.props.activation ? I18n.t('reset.activation_account') : I18n.t('reset.title');

    return (
      <div className="sign-form">
        <form id="form" onSubmit={this.handleSubmit}>
          {this.props.activation && <input type="hidden" name="user[activation]" value={true} />}

          {this.state.flashes.map((o, i) => {
            return (
              <p key={i} className={`login-newui__alert login-newui__alert--info ${o[0]} information`}>
                {o[1]}
              </p>
            );
          })}

          <h1 className="login-newui__h1">{titleText}</h1>
          <ul className="sign-form">
            <div>
              <li className="sep">
                <label className="login-newui__label" htmlFor="password">
                  {I18n.t('reset.label')}
                </label>
                <input
                  type="password"
                  name="user[password]"
                  id="password"
                  tabIndex="1"
                  className="login-newui__input"
                  autoFocus="autoFocus"
                />
                <small>{this.errorsFor('password')}</small>
              </li>

              <li className="sep">
                <label className="login-newui__label margin--top1" htmlFor="email">
                  {I18n.t('reset.password')}
                </label>
                <input
                  type="password"
                  name="user[password_confirmation]"
                  id="password_confirmation"
                  tabIndex="2"
                  className="login-newui__input"
                />
                <small>{this.errorsFor('password_confirmation')}</small>
              </li>

              {this.state.two_step && (
                <li className="sep">
                  <label className="login-newui__label" htmlFor="otp_code">
                    {I18n.t('login.two_step')}
                  </label>
                  <input
                    type="text"
                    name="user[otp_code]"
                    id="otp_code"
                    tabIndex="3"
                    maxLength="6"
                    className="login-newui__input"
                  />
                  <small>{this.errorsFor('otp_code')}</small>
                </li>
              )}
            </div>

            <div className="login-newui__link-container">
              <li className="sep">
                <button className="login-newui__button" disabled={this.state.loading} onClick={this.handleSubmit}>
                  {this.state.loading ? <i className="loader xsmall ico-widt"></i> : buttonText}
                </button>
              </li>

              <p className="sep">
                <a className="login-newui__link" href="/login">
                  {I18n.t('login.link')}
                </a>
              </p>

              <p className="sep">
                <a className="login-newui__link" href="/signup">
                  {I18n.t('signup.question')}
                </a>
              </p>
            </div>
          </ul>
        </form>
      </div>
    );
  }
}

export default Reset;
