import React from 'react';

const EyeVisible = ({ height, width, color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.14777 12.1605C7.28448 12.2449 5.75167 10.7121 5.83956 8.85234C5.86066 8.42344 6.15891 6.85022 7.64659 6.1418L6.11027 4.60547C3.6943 5.83945 2.31691 7.48477 1.27277 8.62383C1.07941 8.83828 1.07589 9.1582 1.26925 9.37266C3.55089 11.8934 5.56183 14.0625 8.99308 14.0625C10 14 11.5 14 13.7392 12.2344L11.8583 10.3535C11.21 11.7149 9.57667 12.1395 9.14777 12.1605ZM16.7275 9.35156C16.9138 9.14766 16.9243 8.83828 16.745 8.62383C14.9064 6.39141 12.3575 3.9375 8.99308 3.9375C7.97003 3.9375 7.02433 4.16953 6.11027 4.60547L7.64659 6.1418C8.01573 5.96602 8.42355 5.85703 8.85245 5.83945C10.7157 5.75508 12.2485 7.28789 12.1607 9.14766C12.1396 9.57656 12.0341 9.98438 11.8583 10.3535L13.7392 12.2344C14.8993 11.3449 15.8942 10.2691 16.7275 9.35156Z" fill={color ? color : 'white'}/>
        <path d="M6.75725 9.17227C6.84163 10.2727 7.72757 11.1586 8.82796 11.243C9.11624 11.2641 10.4979 11.243 11.1518 9.65391C11.1518 9.65391 11.0003 9.5 10.4979 8.99648C10.0853 8.91084 9.76043 8.76017 9.50026 8.5C9.00026 8 9.00026 7.5 9.00026 7.5L8.67517 7.17422L8.35008 6.84844C6.75747 7.33438 6.7329 8.86289 6.75725 9.17227ZM11.2502 9C11.2502 8.9543 11.2467 8.90859 11.2467 8.86289C11.0498 8.95078 10.8354 9 10.6069 9C10.5682 9 10.533 9 10.4979 8.99648L11.1518 9.65391C11.2151 9.44648 11.2502 9.225 11.2502 9ZM9.00022 7.34766C9.00022 7.13672 9.03889 6.93633 9.10921 6.75352C9.07405 6.75352 9.03889 6.75 9.00022 6.75C8.77522 6.75 8.5575 6.78516 8.35008 6.84844L8.67867 7.17715L9.00725 7.50586C9.00374 7.45312 9.00022 7.40039 9.00022 7.34766Z" fill={color ? color : 'white'}/>
    </svg>
  );
}

export default EyeVisible;
