import React from 'react';
import ReactDOM from 'react-dom';
import {Router, Route, Switch} from 'react-router-dom';
import history from '../thewatch/components/history';
import Login from '../credentials/login.js';
import Signup from '../credentials/signup.js';
import Forgot from '../credentials/forgot.js';
import Reset from '../credentials/reset.js';
import Resend from '../credentials/resend.js';
import TwoStepConfirmation from '../credentials/two_step_confirmation.js';
import Header from '../credentials/header.js';
import Footer from '../credentials/footer.js';
import Wizard from '../sso_node/wizard.js';
import Waiting from '../sso_node/waiting.js';
import Setup from '../sso_node/setup.js';
import Configured from '../sso_node/configured.js';
import actioncable from 'actioncable';
import {Debug} from '../thewatch/utils.js';

window.Debug = Debug;

const userLang = () => {
  let key, keyValue;
  key = 'lang';
  keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  if (keyValue) {
    return unescape(keyValue[2]);
  } else {
    return null;
  }
};

const App = {
  cable: actioncable.createConsumer()
};
I18n.locale = userLang();

const ListenPath = function (updateWizard) {
  return history.listen(function (location) {
    let step = location.pathname.match('/auth/configuration/setup') ? 1 : 2;
    return updateWizard(step);
  });
};

const handleSuccess = function (data) {
  let time = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 3000;
  if (data.redirect === true) {
    const bindToState = arguments.length > 2 ? arguments[2] : null;
    return setTimeout(function () {
      if (bindToState && typeof bindToState === 'function') {
        setTimeout(() => {
          bindToState(() => {
            window.location.href = data.path;
          });
          window.location.href = data.path;
        }, 4000);
      }
      return (window.location.href = data.path);
    }, time);
  } else {
    return history.push(data.path);
  }
};

const props = {
  handleSuccess: handleSuccess
};

const LoginWrapper = function (app) {
  if (app.match.params.sso_token) {
    $.extend(props, {
      sso_node: true,
      sso_token: app.match.params.sso_token
    });
  }
  return <Login {...props} />;
};

const SignupWrapper = function (app) {
  let redirect_to_plans;
  if (app.match.params.sso_token) {
    $.extend(props, {
      sso_node: true,
      sso_token: app.match.params.sso_token
    });
  } else {
    redirect_to_plans = app.location.search.match('subscription/new'); //plan comes from prey project pricing page
    if (redirect_to_plans) {
      $.extend(props, {
        plan: redirect_to_plans.input.split('plan=')[1],
        redirect_url: redirect_to_plans.input.split('redirect=')[1]
      });
    }
  }
  return <Signup {...props} />;
};

const ForgotWrapper = function () {
  return <Forgot {...props} />;
};

const ActivationWrapper = function (app) {
  $.extend(props, {activation: true});
  return <Reset {...props} />;
};

const ResetWrapper = function (app) {
  return <Reset {...props} />;
};

const TwoStepConfirmationWrapper = function () {
  return <TwoStepConfirmation {...props} />;
};

class MainRouter extends React.Component {
  subscribe = () => {
    return (App.events = App.cable.subscriptions.create({
      channel: 'PublicChannel',
      room: window.visitor_ip,
      language: window.language,
      country: window.country,
      user_agent: window.user_agent
    }));
  };

  componentDidMount = () => {
    this.subscribe();
  };

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={LoginWrapper} />

          <Route exact path="/login" component={LoginWrapper} />
          <Route exact path="/login/:sso_token" component={LoginWrapper} />

          <Route exact path="/session" component={LoginWrapper} />
          <Route exact path="/session/new" component={LoginWrapper} />

          <Route exact path="/signup" component={SignupWrapper} />
          <Route exact path="/signup/:sso_token" component={SignupWrapper} />

          <Route exact path="/forgot" component={ForgotWrapper} />
          <Route exact path="/reset/:code" component={ResetWrapper} />
          <Route exact path="/activation/:code" component={ActivationWrapper} />

          <Route exact path="/resend" component={Resend} />

          <Route exact path="/auth/otp_auth" component={TwoStepConfirmationWrapper} />
          <Route exact path="/auth/otp_auth/:email" component={TwoStepConfirmationWrapper} />
          <Route exact path="/auth/otp_auth/:email/:sso_token" component={TwoStepConfirmationWrapper} />

          <Route exact path="/auth/configuration/waiting" component={Waiting} />
          <Route exact path="/auth/configuration/waiting/:sso_token" component={Waiting} />

          <Route exact path="/auth/configuration/setup" component={Setup} />
          <Route exact path="/auth/configuration/configured" component={Configured} />
          {/* <Route exact path="/auth/configuration/no_space_left" component={NoSpaceLeft} /> */}
        </Switch>
      </Router>
    );
  }
}

// weird behavior in android chrome
if (I18n.locale === null) {
  window.location = window.location;
} else {
  // ESTO ES UNA MALA PRACTICA
  if (document.getElementById('wizardContent') !== null) {
    ReactDOM.render(<Wizard listenPath={ListenPath} />, document.getElementById('wizardContent'));
  }
  ReactDOM.render(<Header />, document.getElementById('header'));
  ReactDOM.render(<MainRouter />, document.getElementById('appRender'));
  if (document.getElementById('footer') !== null) {
    ReactDOM.render(<Footer />, document.getElementById('footer'));
  }
}
