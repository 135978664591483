export const isProduction = () => {
  return window.location.hostname === "panel.preyproject.com"
}

export const isDevelopment = () => {
  return window.location.hostname === "panel.test" || window.location.hostname === "localhost" 
}

export const isStaging = () => {
  const h = window.location.hostname;
  return h.match(/^panel\d?\.preyhq\.com$/gm);
}

export const onEnv = (opts) => {
  if (opts.prod && isProduction()){
    return opts.prod;
  }

  if (opts.stg && isStaging()){
    return opts.stg;
  }

  if (opts.dev && isDevelopment()){
    return opts.dev;
  }

  if (opts.else){
    return opts.else;
  }
}
