import React from 'react';
import serialize from 'form-serialize';
import SsoLinks from '../sso_node/sso_links.js';
import {client} from '../thewatch/fetch/client.js';

class TwoStepConfirmation extends React.Component {
  state = {
    flashes: [],
    two_step: false,
    redirect: false,
    email_encoded: '',
    email: '',
    error: null,
    loading: false,
    loading_get: true,
    title: null,
    sso_token: null,
    show_content: true
  };

  componentDidMount() {
    if (this.props.flashes) {
      return this.setState({
        title: this.props.flashes[0][1],
        email: this.props.email,
        sso_token: this.props.sso_token,
        loading_get: false
      });
    } else {
      client('GET', `${location.pathname}.json`)
        .then((response) => {
          const data = response.data;
          let obj = {
            email: data.email
          };
          if (data.email_encoded) {
            obj['email_encoded'] = data.email_encoded;
          }
          if (data.flash) {
            obj['title'] = data.flash[0][1];
          }
          if (data.two_step) {
            obj['two_step'] = data.two_step;
          }
          obj['sso_token'] = data.sso_token;
          obj['sso_node'] = data.sso_node;
          obj['loading_get'] = false;
          this.setState(obj);
        })
        .catch((response) => {
          const error = response.error;
          console.error(error);
        });
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();
    this.setState({
      loading: true
    });

    const data = serialize(ev.currentTarget, {empty: true, hash: true});

    client('POST', '/auth/otp_auth.json', data)
      .then((response) => {
        const data = response.data;
        let obj = {
          path: data.path
        };
        if (data.flash) {
          obj['flashes'] = data.flash;
        }
        if (data.two_step) {
          obj['two_step'] = data.two_step;
        }
        if (data.redirect) {
          obj['redirect'] = data.redirect;
        }
        obj['loading'] = false;
        this.setState(obj, () => {
          if (!data.path) return;

          this.setState({
            show_content: false
          });

          let time = data.path === '/auth/configuration/waiting' ? 1 : 100;
          this.props.handleSuccess(data, time);
        });
      })
      .catch((response) => {
        const error = response.error;
        this.setState({
          state: error.errors,
          flashes: error.flash,
          loading: false
        });
      });
  };

  backToCallback = (ev) => {
    ev.preventDefault();
    return history.back();
  };

  render() {
    return (
      <div>
        {this.state.flashes.map((o, i) => {
          return (
            <p key={i} className={`login-newui__alert login-newui__alert--info ${o[0]} information`}>
              {o[1]}
            </p>
          );
        })}

        {this.state.show_content ? (
          <form
            autoComplete="off"
            ref="form"
            className="form form-session"
            id="two-step-confirmation"
            action="/auth/otp_auth"
            acceptCharset="UTF-8"
            method="post"
            onSubmit={this.handleSubmit}
          >
            <ul className="sign-form">
              <li>
                <input type="hidden" name="email" value={this.state.email} />
              </li>
              <li className="sep">
                <p className="here">{this.state.title}</p>
              </li>
              <li className="sep">
                <label className="tag-label" htmlFor="otp_code">
                  {this.state.email}
                </label>
                <input
                  type="text"
                  name="otp_code"
                  id="otp_code"
                  className="form-control"
                  tabIndex="1"
                  autoFocus={true}
                  maxLength="6"
                />
              </li>
              <li>
                <input
                  type="submit"
                  name="commit"
                  tabIndex="2"
                  value={this.state.loading ? I18n.t('login.submitting') : I18n.t('login.submit')}
                  className="cta xl"
                  disabled={this.state.loading}
                />
              </li>

              {!this.state.loading_get ? (
                this.state.sso_token !== void 0 ? (
                  <SsoLinks sso_token={this.state.sso_token} />
                ) : (
                  <div>
                    <li className="sep">
                      <a className="login-newui__link" href="/forgot" target="_blank">
                        {I18n.t('forgot.question')}
                      </a>
                    </li>

                    <li className="sep">
                      <a className="login-newui__link" href="#" onClick={this.backToCallback}>
                        {I18n.t('login.back_to_login')}
                      </a>
                    </li>
                  </div>
                )
              ) : (
                void 0
              )}
            </ul>
          </form>
        ) : (
          void 0
        )}
      </div>
    );
  }
}

export default TwoStepConfirmation;
