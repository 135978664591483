import React from 'react';
import history from '../thewatch/components/history';
import NewIcoPrey from '../thewatch/new-ui/components/svg/newIcoPrey.js';
import {client} from '../thewatch/fetch/client.js';

class Setup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: false,
      email: '',
      setup_version: null,
      sso_token: null,
      loading: true
    };
  }

  componentDidMount() {
    client('GET', '/auth/configuration/setup.json').then((response) => {
      const data = response.data;
      this.setState({
        logged_in: data.logged_in,
        email: data?.email,
        setup_version: data.setup_version,
        sso_token: data.token,
        loading: false,
        availableSlots: data.available_slots
      });
    });
  }

  getSsoToken = () => {
    if (this.state.sso_token === null) {
      location.href = '/';
      return false;
    } else {
      return this.state.sso_token;
    }
  };

  useCurrentAccount = (ev) => {
    ev.preventDefault();
    return (location.href = '/auth/configuration/waiting/' + this.getSsoToken());
  };

  goToLogin = (ev) => {
    ev.preventDefault();
    return history.push('/login/' + this.getSsoToken());
  };

  goToSignup = (ev) => {
    ev.preventDefault();
    return history.push('/signup/' + this.getSsoToken());
  };

  render() {
    return (
      !this.state.loading && (
        <div className="session-container">
          <div className="top-icon">
            <figure>
              <NewIcoPrey />
            </figure>
          </div>
          <div className="flex-column gap-24">
            <h1 className="login-newui__h1">
              {I18n.t('node_config.configuring_title', {version: this.state.setup_version})}
            </h1>
            {this.state.logged_in ? (
              <div className="login-newui__desc">
                <span
                  className="login-newui__p"
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('node_config.configuring_desc', {
                      email: this.state.email
                    })
                  }}
                />
              </div>
            ) : (
              <div>{I18n.t('node_config.configuring_subtitle')}</div>
            )}
          </div>
          <form className="form form-session">
            <div className="login-newui__link-container flex-column gap-24">
              {this.state.logged_in ? (
                <button className="button button--primary expanded" onClick={this.useCurrentAccount}>
                  <span>{I18n.t('node_config.existing_user.title_2')}</span>
                </button>
              ) : (
                <button className="button button--primary expanded" onClick={this.goToLogin}>
                  <span>{I18n.t('node_config.existing_user.title')}</span>
                </button>
              )}
              <button className="button button--secondary expanded" onClick={this.goToSignup}>
                <span>
                  {this.state.logged_in ? I18n.t('node_config.new_user.title_2') : I18n.t('node_config.new_user.title')}
                </span>
              </button>
            </div>
          </form>
        </div>
      )
    );
  }
}

export default Setup;
