import React from 'react';

const EyeInvisible = ({ height, width, color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.10441 2.39409C2.90753 2.20074 2.59113 2.20074 2.39777 2.39761C2.20441 2.59097 2.20441 2.90738 2.39777 3.10074L14.8958 15.6058C15.0259 15.7359 15.2122 15.7781 15.3775 15.7324C15.4618 15.7113 15.5392 15.6656 15.6025 15.6023C15.7958 15.4089 15.7958 15.0925 15.6025 14.8992L3.10441 2.39409ZM9.14777 12.1605C7.28448 12.2449 5.75167 10.7121 5.83956 8.8523C5.86066 8.42339 5.96613 8.01558 6.14191 7.64644L4.26456 5.76558C3.29073 6.51792 2.31691 7.48472 1.27277 8.62378C1.07941 8.83824 1.07589 9.15816 1.26925 9.37261C3.55089 11.8933 5.56183 14.0625 8.99308 14.0625C10.0372 14.0625 11.0005 13.8023 11.8864 13.3875L10.3571 11.8582C9.98448 12.0339 9.57667 12.1394 9.14777 12.1605ZM16.7275 9.35152C16.9138 9.14761 16.9243 8.83824 16.745 8.62378C14.9064 6.39136 12.3575 3.93745 8.99308 3.93745C7.97003 3.93745 7.02433 4.16949 6.11027 4.60542L7.64659 6.14175C8.01573 5.96597 8.42355 5.85699 8.85245 5.83941C10.7157 5.75503 12.2485 7.28785 12.1607 9.14761C12.1396 9.57652 12.0341 9.98433 11.8583 10.3535L13.7392 12.2343C14.8993 11.3449 15.8942 10.2691 16.7275 9.35152Z" fill={color ? color : 'white'}/>
      <path d="M6.75687 9.17227C6.84125 10.2727 7.72719 11.1586 8.82758 11.243C9.11586 11.2641 9.39359 11.2324 9.65023 11.1516L6.84476 8.34609C6.76742 8.60625 6.73578 8.88398 6.75687 9.17227ZM11.2498 9C11.2498 8.9543 11.2463 8.90859 11.2463 8.86289C11.0495 8.95078 10.835 9 10.6065 9C10.5678 9 10.5327 9 10.4975 8.99648L11.1514 9.65391C11.2147 9.44648 11.2498 9.225 11.2498 9ZM8.99984 7.34766C8.99984 7.13672 9.03851 6.93633 9.10883 6.75352C9.07367 6.75352 9.03851 6.75 8.99984 6.75C8.77484 6.75 8.55687 6.78516 8.34945 6.84844L9.00687 7.50586C9.00336 7.45312 8.99984 7.40039 8.99984 7.34766Z" fill={color ? color : 'white'}/>
    </svg>
  );
}

export default EyeInvisible;
