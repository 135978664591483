import React from 'react';

class SsoLinks extends React.Component {
  render() {
    return (
      <div className="login-newui__link-container">
        <input name="sso_token" type="hidden" value={this.props.sso_token} />

        <p className="sep">
          <a className="login-newui__link" href={`/signup/${this.props.sso_token}`}>
            {I18n.t('signup.question')}
          </a>
        </p>

        {this.props.forgot && (
          <p className="sep">
            <a className="login-newui__link" target="_blank" href="/forgot">
              {I18n.t('forgot.question')}
            </a>
          </p>
        )}
      </div>
    );
  }
}

export default SsoLinks;
